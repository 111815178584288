<template>
	<div class="pdf-viewer">
        <!-- <template v-if="isLoading">
            <div class="pdf-viewer__loader">
                <div class="pdf-viewer__loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </template> -->

        <div class="pdf-viewer__header">
            <div class="pdf-viewer__header-title">
                <img src="@/assets/images/pdf-icon.svg" alt="">
                <span>{{ documentTitle }}</span>
            </div>

            <a
                class="button"
                :download="documentTitle"
                :href="$route.query.url"
            >
                <span>
                    {{ $t('Download') }}
                </span>
            </a>
        </div>

        <div
            ref="pages"
            class="pdf-viewer__pages"
        >
            <pdf
                class="pdf-viewer__page"
                v-for="i in pagesTotal"
                :src="src"
                :key="i"
                :page="i"
                @page-loaded="onPageLoaded"
            ></pdf>
        </div>

        <div
            v-if="!isLoading"
            class="pdf-viewer__toolbar"
        >
            <div>
                {{ currentPage }} / {{ pagesTotal }}
            </div>

            <div>
                <button
                    @click="zoomOut"
                >
                    <img src="@/assets/images/zoom-out.svg?h1" alt="">
                </button>
                <button
                    @click="zoomIn"
                >
                    <img src="@/assets/images/zoom-in.svg?h1s" alt="">
                </button>
            </div>
        </div>
	</div>
</template>

<script>
import pdf from '@teckel/vue-pdf'

function setZoom(zoom, el) {

      let transformOrigin = [0,0];
	    el = el || instance.getContainer();
	    var p = ["webkit", "moz", "ms", "o"],
            s = "scale(" + zoom + ")",
            oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

	    for (var i = 0; i < p.length; i++) {
	        el.style[p[i] + "Transform"] = s;
	        el.style[p[i] + "TransformOrigin"] = oString;
	    }

        console.log(el.style)
	    el.style["transform"] = s;
	    el.style["transformOrigin"] = oString;
}

export default {
	components: {
		pdf
	},
	data() {
		return {
			src: null,
			pagesTotal: undefined,
            currentPage: 1,
            currentZoom: 1,
            pagesLoaded: 0,
            isLoading: true
		}
	},
    computed: {
        documentTitle() {
            return this.$route.query.url.split('/').pop();
        }
    },
    created() {
        const url = this.$route.query.url;
        const loadingTask = pdf.createLoadingTask(window.decodeURIComponent(url));

        this.src = loadingTask;
    },
	mounted() {
		this.src.promise.then(pdf => {
			this.pagesTotal = pdf.numPages;

            setTimeout(() => {
                // Observer to detect current page while scrolling
                const pages = Array.from(this.$refs.pages.querySelectorAll('.pdf-viewer__page'));

                const pagesObserver = new IntersectionObserver(entries => {
                    entries.forEach((entry) => {

                        if (entry.isIntersecting) {
                            this.currentPage = pages.indexOf(entry.target) + 1;
                        }
                    });
                }, {
                    root: null,
                    threshold: 0.7
                });

                pages.forEach(page => {
                    pagesObserver.observe(page);
                });
            }, 1000)
		});
	},
    methods: {
        onPageLoaded() {
            this.pagesLoaded += 1;

            if (this.pagesLoaded === this.pagesTotal) {
                this.isLoading = false;
            }
        },
        zoomIn() {
            this.currentZoom += 0.1;
            setZoom(this.currentZoom, this.$refs.pages);
        },
        zoomOut() {
            if (this.currentZoom === 1) {
                return
            }

            this.currentZoom -= 0.1;
            setZoom(this.currentZoom, this.$refs.pages);
        }
    }
}

</script>
<style>
#demo,
.support-container {
    display: none;
}
/*
.internalLink,
.linkAnnotation {
    display: none
} */

.internalLink,
.linkAnnotation {
    display: none
}

.pdf-viewer {
    background-color: #000;
    width: 100%;
    height: 100%;
    /* max-width: 100%; */
}

.pdf-viewer__header {
    width: 100%;

    background-color: rgba(4, 11, 11, 0.9);
    backdrop-filter: blur(10px);

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 1000; */

    padding: 16px 40px;
    /* max-width: 100%; */
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 1000;  */

    padding: 16px 40px;
    /* max-width: 100%; */
}

.pdf-viewer__header-title {
    display: flex;
    align-items: center;

    color: rgba(162, 220, 217, 0.6);

    max-width: 60%;
}

@media (max-width: 768px) {
    .pdf-viewer__header {
        padding: 12px 20px;
    }

    .pdf-viewer__header-title {
        padding-right: 14px;
    }
}

.pdf-viewer__header-title span {
    /* three dots if its not enough space */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pdf-viewer__header-title span {
    display: inline-block;
    padding-left: 16px;
    font-family: "Inter-Variable", Arial, serif;
}

.pdf-viewer__header .button {
    padding: 12px 26px;
}

.pdf-viewer__loader {
    display: flex;
    align-items: center;
    justify-content: center;

    height: calc(100vh - 79px);
    width: 100%;

    position: fixed;
    top: 79px;
    left: 0;
    z-index: 999;
    /* background-color: #000; */
}

.pdf-viewer__loader-ring {
  /* change color here */
  color: #638886;
}
.pdf-viewer__loader-ring,
.pdf-viewer__loader-ring div {
  box-sizing: border-box;
}
.pdf-viewer__loader-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.pdf-viewer__loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: pdf-viewer__loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.pdf-viewer__loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.pdf-viewer__loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.pdf-viewer__loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes pdf-viewer__loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pdf-viewer__pages {
    margin-top: 80px;
    padding: 0 17px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: calc(100% - 79px);
    width: 100%;
}

.pdf-viewer__page {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
}

.pdf-viewer__page + .pdf-viewer__page {
    margin-top: 12px;
}

.pdf-viewer__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    bottom: 30px;
    left: 50%;
    z-index: 1000;
    transform: translateX(-50%);

    width: 100%;
    max-width: 210px;
    padding: 6px 16px;
    border-radius: 35px;
    max-width: 300px;
    padding: 6px 16px;
    border-radius: 35px;

    background-color: rgba(4, 11, 11, 0.7);
    backdrop-filter: blur(10px);
}

.pdf-viewer__toolbar div:first-child {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    width: 86px;
    text-align: center;
}

.pdf-viewer__toolbar button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
}
</style>