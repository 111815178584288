<template>
    <div>
        <PdfViewer />
        <LndScripts />
    </div>
</template>
<script>
import PdfViewer from '@/components/PdfViewer.vue'
import LndScripts from '@/components/lnd/Scripts.vue'

export default {
    components: {
        PdfViewer,
        LndScripts
    }
}
</script>